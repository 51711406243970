<template>
  <v-col>
    <v-row align="center" justify="space-around">
      <h1>פתיחת עוסק מורשה או פטור בחינם</h1>
      <p class="white black--text font-weight-bold">
        משרדנו שמח להציע ללקוחותינו שירות פתיחת תיק עוסק מורשה או פטור במס הכנסה
        או במע"מ בחינם וללא כל תשלום
      </p>
      <p>
        לפתיחה מקוונת און ליין קלה ומהירה מלאו את הטופס ותוכלו לסמן עוד שלב חשוב
        בפתיחת העסק לסמן כמבוצע
      </p>
    </v-row>
    <v-row class="mt-5" justify="center">
      <OpenNewBizBtn
        v-on:open-form="openForm"
        text="לפתיחת עסק לחצו כאן"
      ></OpenNewBizBtn>
    </v-row>
    <FormDetails :show-form="showForm" v-on:close-form="closeForm" />
  </v-col>
</template>

<script>
import OpenNewBizBtn from "@/components/OpenNewBizBtn";
import FormDetails from "@/components/FormDetails";

export default {
  name: "OpenNewBiz",
  components: {
    OpenNewBizBtn,
    FormDetails
  },
  data: () => ({
    showForm: false
  }),
  methods: {
    openForm() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    }
  }
};
</script>
