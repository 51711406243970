<template>
  <v-btn
    x-large
    rounded
    class="blue  white--text font-weight-bold"
    @click="$emit('open-form')"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "OpenNewBizBtn",
  props: ["text"]
};
</script>

<style scoped></style>
